var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{class:_vm.buttonClasses,on:{"click":_vm.showModal}},[_vm._v(" "+_vm._s(_vm.openModalText)+" ")]),_c('b-modal',{ref:("confirmation-modal-" + _vm.id),attrs:{"size":"lg","hide-footer":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('button',{staticClass:"button button--nostyle ml-auto",on:{"click":function($event){return close()}}},[_c('BIconX')],1)]}}])},[_c('h4',[_vm._v(_vm._s(_vm.$t("common.are_you_sure")))]),_c('div',{staticClass:"content"},[_vm._t("default")],2),_c('div',{staticClass:"button-row"},[_c('button',{staticClass:"button button--link",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),_c('button',{staticClass:"button button--filled-blue",attrs:{"type":"submit"},on:{"click":function () {
            _vm.onAccept();
            this$1.$refs[("confirmation-modal-" + (this$1.id))].hide();
          }}},[_vm._v(" "+_vm._s(_vm.onAcceptButtonText)+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }