<template>
  <div>
    <button @click="showModal" :class="buttonClasses">
      {{ openModalText }}
    </button>
    <b-modal :ref="`confirmation-modal-${id}`" size="lg" hide-footer centered>
      <template #modal-header="{ close }">
        <button class="button button--nostyle ml-auto" @click="close()">
          <BIconX />
        </button>
      </template>
      <h4>{{ $t("common.are_you_sure") }}</h4>
      <div class="content">
        <slot></slot>
      </div>
      <div class="button-row">
        <button type="button" class="button button--link" @click="cancel">
          {{ $t("common.cancel") }}
        </button>
        <button
          type="submit"
          class="button button--filled-blue"
          @click="
            () => {
              onAccept();
              this.$refs[`confirmation-modal-${this.id}`].hide();
            }
          "
        >
          {{ onAcceptButtonText }}
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BIconX } from "bootstrap-vue";

export default {
  name: "confirmation-modal",
  components: { BIconX },
  props: {
    buttonClasses: String,
    openModalText: {
      type: String,
      default: () => this.$t("common.open"),
    },
    onAccept: Function,
    onAcceptButtonText: {
      type: String,
      default: () => this.$t("common.confirm"),
    },
    // id: [String, Number],
    id: Number,
  },
  methods: {
    showModal() {
      this.$refs[`confirmation-modal-${this.id}`].show();
    },
    cancel() {
      this.$refs[`confirmation-modal-${this.id}`].hide();
    },
  },
};
</script>
<style lang="scss" scoped>
h4 {
  margin-bottom: 1em;
}
.button-row {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
  margin-bottom: 0.85rem;
}
.content {
  padding: 1.5rem 0;
}
</style>
